import React, { lazy } from 'react'
const DebtorProfile = lazy(() =>
  import('./DebtorProfile').then(module => ({
    default: module.DebtorProfile,
  }))
)
const DebtorProfileRoutes = [
  {
    props: { exact: true, path: '/debtorprofile' },
    component: <DebtorProfile />,
  },
]

export default DebtorProfileRoutes
