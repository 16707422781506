import React, { lazy } from 'react'
const Booking = lazy(() =>
  import('./Booking').then(module => ({
    default: module.Booking,
  }))
)
const EditBooking = lazy(() =>
  import('./EditBooking').then(module => ({
    default: module.EditBooking,
  }))
)
const EditRoomType = lazy(() =>
  import('./EditRoomType').then(module => ({
    default: module.EditRoomType,
  }))
)
// const GuestList = lazy(() =>
//   import('./GuestList').then(module => ({
//     default: module.GuestList,
//   }))
// )
const ContractDetails = lazy(() =>
  import('./ContractDetails').then(module => ({
    default: module.ContractDetails,
  }))
)
const AdvancePaymentListing = lazy(() =>
  import('./AdvancePaymentListing').then(module => ({
    default: module.AdvancePaymentListing,
  }))
)
const AddBooking = lazy(() =>
  import('./AddBooking').then(module => ({
    default: module.AddBooking,
  }))
)

const BookingSubMenu = lazy(() =>
  import('./BookingSubMenu').then(module => ({
    default: module.SubMenu,
  }))
)

const RoomingList = lazy(() =>
  import('./RoomingList/RoomingList').then(module => ({
    default: module.RoomingList,
  }))
)

const GuestList = lazy(() =>
  import('./RoomingList/GuestList').then(module => ({
    default: module.GuestList,
  }))
)

const GuestForm = lazy(() =>
  import('./RoomingList/GuestForm').then(module => ({
    default: module.GuestForm,
  }))
)

const AdvpaymentList = lazy(() =>
  import('./AdvancePayment/AdvpaymentList').then(module => ({
    default: module.AdvpaymentList,
  }))
)

const AdvancePaymentDoc = lazy(() =>
  import('./AdvancePayment/AdvpaymentDoc').then(module => ({
    default: module.AdvancePaymentDoc,
  }))
)

export const addBookingPath = '/home/newbooking'
export const BookingSubMenuPath = '/home/bookingdetails'
export const editBookingPath = '/home/editbooking'
export const roomingListPath = '/submenu/roominglist'
export const guestListPath = '/submenu/roominglist/guest'
export const guestFormAddPath = '/submenu/roominglist/guest/add'
export const guestFormEditPath = '/submenu/roominglist/guest/edit'
export const AdvpaymentListPath = '/submenu/advancepayment'
export const advancePaymentDocPath = '/submenu/advancepayment/folio'

const bookingRoutes = [
  {
    props: { exact: true, path: '/booking' },
    component: <Booking />,
  },
  {
    props: { exact: true, path: editBookingPath },
    component: <EditBooking />,
  },
  {
    props: { exact: true, path: '/booking/editroomtype' },
    component: <EditRoomType />,
  },
  // {
  //   props: { exact: true, path: '/booking/editroomtype/guestlist' },
  //   component: <GuestList />,
  // },
  {
    props: { exact: true, path: guestListPath },
    component: <GuestList />,
  },
  {
    props: { exact: true, path: '/booking/advancepayment' },
    component: <AdvancePaymentListing />,
  },
  {
    props: { exact: true, path: '/booking/contract' },
    component: <ContractDetails />,
  },
  {
    props: { exact: true, path: addBookingPath },
    component: <AddBooking />,
  },
  {
    props: { exact: true, path: BookingSubMenuPath },
    component: <BookingSubMenu />,
  },
  {
    props: { exact: true, path: roomingListPath },
    component: <RoomingList />,
  },
  {
    props: { exact: true, path: guestFormAddPath },
    component: <GuestForm mode={'Add'} />,
  },
  {
    props: { exact: true, path: guestFormEditPath },
    component: <GuestForm mode={'Edit'} />,
  },
  {
    props: { exact: true, path: AdvpaymentListPath },
    component: <AdvpaymentList />,
  },
  {
    props: { exact: true, path: advancePaymentDocPath },
    component: <AdvancePaymentDoc mode={'Booking'} type={'Receipt'} />,
  },
]

export default bookingRoutes
