import React, { lazy } from 'react'
const Home = lazy(() =>
  import('../BookingModule/Booking').then(module => ({
    default: module.Booking,
  }))
)
const HotelList = lazy(() =>
  import('./Hotel/HotelList').then(module => ({
    default: module.HotelList,
  }))
)
const HotelPackage = lazy(() =>
  import('./Hotel/HotelPackage').then(module => ({
    default: module.HotelPackage,
  }))
)
const HotelDetail = lazy(() =>
  import('./Hotel/HotelDetail').then(module => ({
    default: module.HotelDetail,
  }))
)
const HotelRoomTypeDetail = lazy(() =>
  import('./Hotel/HotelRoomTypeDetail').then(module => ({
    default: module.HotelRoomTypeDetail,
  }))
)
const HotelBookingForm = lazy(() =>
  import('./Hotel/HotelBookingForm').then(module => ({
    default: module.HotelBookingForm,
  }))
)
const PaymentDocument = lazy(() =>
  import('./Hotel/PaymentDocument').then(module => ({
    default: module.PaymentDocument,
  }))
)
const HomeRoutes = [
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home/redirect/:status' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/booking/search/hotel-list' },
    component: <HotelList />,
  },
  {
    props: { exact: true, path: '/booking/search/hotel-list/packages' },
    component: <HotelPackage />,
  },
  {
    props: {
      exact: true,
      path: '/booking/search/hotel-list/packages/hotel-detail',
    },
    component: <HotelDetail />,
  },
  {
    props: {
      exact: true,
      path: '/booking/search/hotel-list/packages/book-summary',
    },
    component: <HotelBookingForm />,
  },
  {
    props: {
      exact: true,
      path: '/booking/search/hotel-list/packages/roomtype-detail',
    },
    component: <HotelRoomTypeDetail />,
  },
  {
    props: { exact: true, path: '/hotel/detail/payment/redirect/:status' },
    component: <PaymentDocument mode="booking" />,
  },
]

export default HomeRoutes
