import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import createStyles from '@material-ui/core/styles/createStyles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AccountCircle } from '@material-ui/icons'
import clsx from 'clsx'
import HomeIcon from 'components/Svg/Home'
import MyBookingIcon from 'components/Svg/MyBooking'
import ServicesIcon from 'components/Svg/Services'
import AppContext from 'containers/App/Store/AppContext'
import React, { cloneElement, useContext, useEffect } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router'

import Drawer from './Drawer'
import Header from './Header'

const drawerWidth = 240
interface Props {
  children: any
}
const Layout = (props: Props) => {
  const { children } = props
  let settings = useRouteMatch({
    path: [
      '/authentication/404',
      '/login',
      '/forgot',
      '/user/reset-password/:token',
    ],
    strict: true,
    sensitive: true,
  })
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },

      mainContent: {
        position: 'relative',
        top: settings ? 64 : 70,
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('sm')]: {
          top: settings ? 64 : 70,
          marginLeft: -drawerWidth,
          padding: theme.spacing(3),
        },
      },

      contentShift: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      },

      toolbar: theme.mixins.toolbar,
    })
  )
  const classes = useStyles()

  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()

  // Desktop Checking
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  // Drawer Toggler
  // const [openDrawer, setOpenDrawer] = useState(true)
  // const handleDrawerToggle = () => {
  //   setOpenDrawer(!openDrawer)
  // }
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    })
  }
  const { globalState, dispatch } = useContext(AppContext as any)
  const bottomNav = {
    '/home': 0,
    '/account-status': 1,
    '/debtorprofile': 2,
    // '/debtorprofile': 3,
  }
  const handleBottomNav = mode => bottomNav[mode] || 0
  useEffect(() => {
    dispatch({
      type: 'bottomNavPosition',
      payload: handleBottomNav(location?.pathname),
    })
  }, [dispatch, location])
  const NavigationRoutes = [
    {
      label: 'Home',
      icon: <HomeIcon />,
      props: {
        onClick: () => history.push('/home'),
        disabled: false,
      },
    },
    {
      label: 'Account Status',
      icon: <ServicesIcon />,
      props: {
        onClick: () => history.push('/account-status'),
        disabled: false,
      },
    },
    {
      label: 'Contract',
      icon: <AccountCircle />,
      props: {
        onClick: () => history.push('/debtorprofile'),
        disabled: false,
      },
    },

    // {
    //   label: 'Profile',
    //   icon: <AccountCircle />,
    //   props: {
    //     disabled: false,
    //     onClick: () => history.push('/debtorprofile'),
    //   },
    // },
  ]
  // console.log('globalState drawer', globalState)
  return (
    <div className={classes.root}>
      {!settings &&
        (isDesktop ? (
          <Header onDrawerOpen={handleDrawer} />
        ) : // <Header onDrawerOpen={handleDrawer} />
          null)}
      {!settings && (
        <Drawer
          onCloseDrawer={handleDrawer}
          open={isDesktop ? globalState.drawerOpen : !globalState.drawerOpen}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
      )}
      <main
        className={clsx(classes.mainContent, {
          [classes.contentShift]: globalState.drawerOpen,
        })}
      >
        {cloneElement(children, {})}
      </main>

      {location?.pathname === '/home' ||
        location?.pathname === '/home/redirect/:status' ||
        location?.pathname === '/booking' ||
        location?.pathname === '/account-status' ||
        location?.pathname === '/debtorprofile' ? (
        <BottomNavigation
          value={globalState?.bottomNavPosition}
          onChange={(event, newValue) => {
            console.log(newValue, 'newvalue')
            dispatch({
              type: 'bottomNavPosition',
              payload: newValue,
            })
          }}
          showLabels
          className="bottom-navigation"
          style={{ color: '#ed9720' }}
        >
          {NavigationRoutes?.map((v, index) => (
            <BottomNavigationAction
              key={index}
              label={v.label}
              icon={v.icon}
              {...v.props}
            />
          ))}
        </BottomNavigation>
      ) : null}
    </div>
  )
}
export default Layout
