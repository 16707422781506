import { ForgotPassword } from 'containers/AccountModule/ForgotPassword'
import { Login } from 'containers/AccountModule/Login'
import { Logout } from 'containers/AccountModule/Logout'
import { ResetPassword } from 'containers/AccountModule/ResetPassword'
// import { Register } from 'containers/AccountModule/Register'
import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppContext from '../Store/AppContext'
import { PrivateRoute } from './PrivateRoute'
import RouteSuspense from './RouteSuspense'

const Routes = () => {
  const { globalState } = useContext(AppContext)
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>

      <Route path="/forgot">
        <ForgotPassword />
      </Route>
      <Route path="/user/reset-password/:token">
        <ResetPassword />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      {globalState.routes.map((el, index) => {
        return (
          <PrivateRoute key={index} {...el.props}>
            <RouteSuspense>{el.component}</RouteSuspense>
          </PrivateRoute>
        )
      })}
    </Switch>
  )
}

export default Routes
